import React from 'react';
import {useForm, ValidationError} from '@formspree/react';
import {Button, Form} from "react-bootstrap";
import {FormColoredInputs} from "./FormColoredInputs";

export function ApplicationForm({className}) {
  const [state, handleSubmit] = useForm("xzbyzlry");
  if (state.succeeded) {
    return <h3>Your application was received.</h3>;
  }
  if (state.errors && state.errors.length > 0) {
    return (<><h3>Your application has errors:</h3>
      {state.errors.map(msg => (<p>{msg.message}</p>))}
    </>)
  }
  return (
    <FormColoredInputs onSubmit={handleSubmit} className={className}>
      <Form.Group controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Form.Control name="email" type="email" placeholder="address@example.com"/>
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
      </Form.Group>
      <br/>
      <Form.Group controlId="resume">
        <Form.Label>Link to Resume</Form.Label>
        <Form.Control name="resume" type="url" placeholder="https://www.dropbox.com/abcdefj"/>
        <ValidationError
          prefix="Resume"
          field="resume"
          errors={state.errors}
        />
      </Form.Group>
      <br/>
      <Form.Group controlId="message">
        <Form.Label>Brief Introduction</Form.Label>
        <Form.Control name="message" as="textarea" rows={3}/>
        <ValidationError
          prefix="Introduction"
          field="message"
          errors={state.errors}
        />
      </Form.Group>
      <br/>
      <Form.Group>
        <Button variant="primary" type="submit" disabled={state.submitting}>Submit</Button>
      </Form.Group>
    </FormColoredInputs>
  );
}
