import React from 'react'
import {Layout} from "../components/Layout";
import {Col} from "react-bootstrap";
import {BrightRow, SkyRow4, SkyRow6} from "../components/Rows";
import {RowClassesAlignCenter} from "../components/RowStyles";
import NavbarSpacer from "../components/NavbarSpacer";
import {ApplicationForm} from "../components/ApplicationForm";

export default function Careers(props) {
  return (
    <Layout>
      <SkyRow4 className={RowClassesAlignCenter}>
        <NavbarSpacer/>
        <Col md={{span: 3}}>
          <h3>Hidden Switch Careers</h3>
        </Col>
        <Col md={{span: 9}} className="p-0 p-lg-4">
          <h2>Internships for current students and recent graduates</h2>
          <p>Hidden Switch, a venture-funded experimental video games company spinoff from the MIT Media Lab, is seeking
            a CS/arts passionate college or recent grad part time / intern for a summer position in video game
            development.</p>
          <h4>Requirements</h4>
          <ol>
            <li>Currently enrolled or recently graduated a 4-year college program.</li>
            <li>Completed the equivalent of a Harvard CS 121 / MIT 6.006 competency in computer science coursework.</li>
            <li>US Residency</li>
          </ol>
          <p>Candidates with competitive resumes will be asked to complete a short programming quiz.</p>
          <p>Because of the breadth of work available, the key required experience is playing a broad set of video
            games. That means AAA games, indie games, competitive multiplayer games and mobile puzzle games. The team
            here is fans of Hearthstone, Overwatch, Breath of the Wild, Super Smash, Into the Breach, Slay the Spire,
            Papers Please, Threes, Two Dots, Brawl Stars—if this describes you, reach out to us!</p>
        </Col>
      </SkyRow4>
      <SkyRow6 className={RowClassesAlignCenter}>
        <Col md={{span: 3}}>
          <h3>Apply Now</h3>
        </Col>
        <Col md={{span: 9}} className="p-0 p-lg-4">
          <ApplicationForm />
        </Col>
      </SkyRow6>
    </Layout>
  )
}
